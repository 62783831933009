import { Api, Models } from '@core/types';
import { ProjectEnums, CategoryEnums } from '@core/enums';
import { DownloadsByCulture, DownloadsByUser, PrintsByCulture, PrintsByUser, ProjectPDF } from './models';

export enum Method {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
}

export interface IRequest {
  method: Method;
  slug: string;
  params?: any;
  controller: string;
  cacheKey?: string;
  bearerToken?: string;
  baseUrl?: string;
}

export interface IApiError {
  fieldName: string;
  message: string;
}

export interface IBaseResponse<T> {
  data: T | null;
  errors: IApiError[];
  success: boolean;
}

export interface IBaseAuthorisedRequest {
  bearerToken: string;
}

export interface IFetchProjectsRequest extends IBaseAuthorisedRequest {}

export interface IFetchProjectsResponse {
  projects: Models.Project[];
}

export interface IFetchProjectRequest extends IBaseAuthorisedRequest {
  projectGUID: string;
}

export interface IFetchProjectPdfRequest extends IBaseAuthorisedRequest {
  projectPDFID: number;
}

export interface IFetchProjectPdfResponse {
  projectPDF: ProjectPDF;
}

export interface IFetchProjectResponse {
  project: Models.Project;
}

export interface ICreateProjectRequest extends IBaseAuthorisedRequest {
  projectName: string;
}

export interface ICreateProjectResponse {
  projectGUID: string;
}

export interface IDeleteProjectRequest extends IBaseAuthorisedRequest {
  projectGUID: string;
}

export interface IFetchProductsRequest extends IBaseAuthorisedRequest {
  cultureCode: string;
  articleNumbers?: string[];
  systems?: CategoryEnums.SystemIDs[];
  variantAgilityIDs?: number[];
  categoryAgilityIDs?: CategoryEnums.AgilityIDs[];
  searchTerm?: string;
}

export interface IFetchProductsResponse {
  categories: Models.Category[];
}

export interface IFetchNavigationResponse {
  powerTools: Models.Category | null;
  gardenTools: Models.Category | null;
  batteriesChargers: Models.Category | null;
  accessories: Models.Category | null;
  handTools: Models.Category | null;
  onePlus: Models.Category | null;
  onePlusHP: Models.Category | null;
  maxPower: Models.Category | null;
  _72v: Models.Category | null;
  lithiumUSB: Models.Category | null;
  link: Models.Category | null;
}

export interface IUpdateProjectRequest extends IBaseAuthorisedRequest {
  projectGUID: string;
  projectProducts: Models.ProjectProduct[];
  cultureCode: string;
  refetchProject: boolean;
}

export interface IGeneratePDFRequest extends IBaseAuthorisedRequest {
  projectGUID: string;
  cultureCode: string;
  size: ProjectEnums.PDFSize;
  headerLayout: ProjectEnums.PDFHeaderLayout;
  orderNumber?: string;
  orderCompanyName?: string;
  orderFirstName?: string;
  orderLastName?: string;
  orderEmailAddress?: string;
  orderAddressLine1?: string;
  orderAddressLine2?: string;
  orderCity?: string;
  orderPostcode?: string;
  orderCountry?: string;
  invoicedCountry?: string;
  orderNotes?: string;
  fullDownload: boolean;
  isPrint: boolean;
  printArrangement: ProjectEnums.PrintArrangement;
  showQRCode: boolean;
}

export interface IGeneratePDFResponse {
  projectPDFID: number;
}

export interface IUpdateQuantity {
  agilityID: number;
  quantity: number;
}

export interface IFetchPdfsRequest extends IBaseAuthorisedRequest {
  cultureCode: string[];
  status: ProjectEnums.PrinterApprovalStatus;
}

export interface IFetchPdfsResponse {
  projectPDFs: Models.ProjectPDF[];
}

export interface IPrinterApproveReject extends IBaseAuthorisedRequest {
  projectPDFID: number;
  acceptReject: boolean;
  cultureCode: string;
}

export interface ISendForApprovalRequest extends IBaseAuthorisedRequest {
  projectPDFID: number;
  cultureCode: string;
}

export interface IFetchUserPdfsRequest extends IBaseAuthorisedRequest {
  cultureCode?: string;
  status?: ProjectEnums.PrinterApprovalStatus;
}

export interface IFetchUserPdfsResponse {
  projectPDFs: Models.ProjectPDF[];
}

export interface IFetchReportsRequest extends IBaseAuthorisedRequest {
  dateFrom: string;
  dateTo: string;
}

export interface IFetchReportsResponse {
  printsByUser: PrintsByUser[];
  printsByCulture: PrintsByCulture[];
  downloadsByUser: DownloadsByUser[];
  downloadsByCulture: DownloadsByCulture[];
}

export enum ReportType {
  PrintsByCulture = 0,
  PrintsByUser,
  DownloadsByCulture,
  DownloadsByUser,
}
